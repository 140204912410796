import { useTranslation } from 'react-i18next';
import { PAGE_PATHS, PAGE_ROLES } from './pages';

export interface SubMenu {
  id: number;
  label: string;
  navigate: string;
  roles: Array<string>;
}

export interface Menu {
  title: string;
  subMenus: Array<SubMenu>;
}

const MENU = () => {
  const { t } = useTranslation(['common', 'sidebar']);
  return [
    {
      title: t('phones'),
      subMenus: [
        { id: 1, label: t('phone.list'), navigate: PAGE_PATHS.PHONES, roles: PAGE_ROLES.PHONES },
        {
          id: 2,
          label: t('sharing.requests'),
          navigate: PAGE_PATHS.SHARING_REQUEST,
          roles: PAGE_ROLES.SHARING_REQUEST,
        },
      ],
    },
    {
      title: t('settings'),
      subMenus: [{ id: 3, label: t('sidebar:general'), navigate: PAGE_PATHS.CONFIG, roles: PAGE_ROLES.CONFIG }],
    },
    {
      title: t('sidebar:administration'),
      subMenus: [
        {
          id: 4,
          label: t('permissions.by.product'),
          navigate: PAGE_PATHS.PERMISSIONS,
          roles: PAGE_ROLES.PERMISSIONS,
        },
      ],
    },
  ];
};

export default MENU;
