import ActionsMenu, { ActionMenuOption } from '@components/common/actionsMenu';
import PhoneNumberListDisplay from '@components/common/display/phoneNumber';
import ResponsibleTypeDisplay from '@components/common/display/responsibleType';
import ResponsibleStatus from '@models/ResponsibleStatus';
import Phone from '@models/phone';
import ResponsibleType from '@models/responsibleType';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

type RowFormats = string | ResponsibleType | Array<string> | boolean;

interface Column {
  id: 'phones' | 'type' | 'responsible' | 'project' | 'area' | 'actions';
  label: string;
  minWidth?: number;
  align?: 'right' | 'center';
  format?: (value: RowFormats) => string | JSX.Element;
}

type RowData = {
  id: number;
  phones: Array<string>;
  type: string;
  responsible: string;
  project: string;
  area: string;
  actions: boolean;
  entity: Phone;
};

const emptyStringFormatter = (value: RowFormats): string | JSX.Element => (value ? String(value) : '---');
// const formatStringValue = (text: RowFormats, maxLength?: number | null): string | JSX.Element => <Tooltip title={text}><Typography noWrap maxWidth={maxLength} fontSize={13}> {text ? String(text) : '---'}</Typography></Tooltip>;

const useColumns = (): readonly Column[] => {
  const { t } = useTranslation(['phones', 'common']);

  return [
    {
      id: 'phones',
      label: t('common:phones'),
      minWidth: 140,
      format: (value: RowFormats) => <PhoneNumberListDisplay value={value as Array<string>} />,
    },
    {
      id: 'type',
      label: t('linked'),
      minWidth: 130,
      format: (value: RowFormats) => <ResponsibleTypeDisplay value={value as ResponsibleType} />,
    },
    { id: 'responsible', label: t('responsible'), minWidth: 200, format: emptyStringFormatter },
    { id: 'project', label: t('common:cost.center'), minWidth: 200, format: emptyStringFormatter },
    { id: 'area', label: t('observation'), minWidth: 200, format: emptyStringFormatter },
    { id: 'actions', label: t('common:actions'), minWidth: 60, align: 'center' },
  ];
};

const createRows = (content: Array<Phone>) =>
  content.map(
    (phone) =>
      ({
        id: phone.id,
        phones: phone.phones,
        type: phone.type,
        responsible: phone.type === ResponsibleType.PERSON ? phone.personName : '',
        project: phone.projectCode ? `${phone.projectCode} - ${phone.projectName}` : '',
        area: phone.area,
        actions: true,
      } as RowData),
  );

interface PhoneTableRowProps {
  row: RowData;
  onClickRow: (clickedRow: RowData) => void;
  onDelete: (selectedRow: RowData) => void;
}

function PhoneTableRow(props: PhoneTableRowProps) {
  const { row, onClickRow: handleOnClickRow, onDelete } = props;

  const { t } = useTranslation('phones');

  const columns = useColumns();

  return (
    <TableRow
      hover
      role="checkbox"
      tabIndex={-1}
      key={row.id}
      sx={{
        height: 40,
        cursor: 'pointer',
        '&:nth-of-type(odd)': {
          backgroundColor: 'white',
        },
        '&:nth-of-type(even)': {
          backgroundColor: '#FAFAFA',
        },
      }}
    >
      {columns.map((column) => {
        const value = row[column.id];
        const key = `${column.id}-${row.id}`;
        const formattedValue = column.format ? column.format(value) : value;
        if (column.id === 'actions') {
          return (
            <TableCell key={key} align="center" scope="row" sx={{ p: 0, borderBottom: 0 }}>
              <ActionsMenu
                actionList={
                  [
                    {
                      icon: <EditOutlinedIcon />,
                      label: t('edit.phone'),
                      actionCallback: () => handleOnClickRow(row),
                    },
                    {
                      icon: <DeleteOutlineOutlinedIcon />,
                      label: t('delete.phone'),
                      actionCallback: () => onDelete(row),
                    },
                  ] as Array<ActionMenuOption>
                }
              />
            </TableCell>
          );
        }
        return (
          <TableCell
            key={key}
            align="left"
            scope="row"
            sx={{ borderBottom: 0, maxWidth: column.minWidth, overflow: 'hidden', textOverflow: 'ellipsis' }}
            onClick={() => handleOnClickRow(row)}
          >
            {formattedValue}
          </TableCell>
        );
      })}
    </TableRow>
  );
}

type PhonesTableProps = {
  phones: Array<Phone>;
  inactivePhones: Array<Phone>;
  tab: ResponsibleStatus;
  onRowSelect: (phone: Phone) => void;
  onDelete: (phone: Phone) => void;
};

export default function PhonesTable(props: PhonesTableProps) {
  const { t } = useTranslation('phones');
  const [rows, setRows] = useState<Array<RowData>>([]);
  const { phones, inactivePhones, tab, onRowSelect, onDelete } = props;

  const columns = useColumns();

  useEffect(() => {
    if (tab === ResponsibleStatus.ACTIVE) {
      setRows(createRows(phones));
    } else {
      setRows(createRows(inactivePhones));
    }
  }, [tab, phones, inactivePhones]);

  const rowToPhone = (row: RowData): Phone => {
    const phoneList = tab === ResponsibleStatus.ACTIVE ? phones : inactivePhones;
    const findPhone = phoneList.filter((phone: Phone) => phone.id === row.id);
    if (findPhone.length === 0) {
      toast.error(`${t('O telefone selecionado não existe mais...')}`, {
        position: toast.POSITION.TOP_RIGHT,
        delay: 1,
      });
    }
    return findPhone[0];
  };

  const handleRowClick = (row: RowData) => {
    if (onRowSelect) {
      onRowSelect(rowToPhone(row));
    }
  };

  const handleDelete = (row: RowData) => {
    if (onDelete) {
      onDelete(rowToPhone(row));
    }
  };

  return (
    <TableContainer>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="Phones">
        <TableHead sx={{ fontWeight: 700 }}>
          <TableRow>
            {columns.map((column) => (
              <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth, fontWeight: 'bold' }}>
                {t(column.label)}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody sx={{ borderBottom: 1, borderBottomColor: '#DEDEDE', borderTop: 1, borderTopColor: '#DEDEDE' }}>
          {rows &&
            rows.map((row: RowData) => (
              <PhoneTableRow key={row.id} row={row} onClickRow={handleRowClick} onDelete={handleDelete} />
            ))}
          {rows.length === 0 && (
            <TableRow sx={{ cursor: 'pointer' }}>
              <TableCell colSpan={columns.length} align="center">
                <Typography sx={{ fontSize: 16, color: '#A8A8A8' }}>{t('Nenhum registro foi encontrado')}</Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
