import StandardDialog from '@/components/common/StandardDialog';
import PhoneNumberListDisplay from '@/components/common/display/phoneNumber';
import PageTitle from '@components/common/pageTitle';
import PhoneTabs from '@components/phones/activeInactiveTab';
import NewEditPhoneScreen from '@components/phones/newEditPhone';
import PhonesTable from '@components/phones/table';
import SearchComponent from '@components/search';
import ResponsibleStatus from '@models/ResponsibleStatus';
import Phone from '@models/phone';
import { Button, Drawer, TablePagination } from '@mui/material';
import Box from '@mui/material/Box';
import { useDeletePhoneMutation, useInactivePhonesQuery, usePhonesQuery } from '@services/phoneApi';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useThrottle } from 'react-use';

const ROWS_PER_PAGE_OPTIONS = [10, 25, 50];
const FIRST_PAGE = 0;
const ON_CHANGE_DELAY = 500;

export default function ManagePhonesPage() {
  const [filterValueActive, setFilterValueActive] = useState('');
  const [filterValueInactive, setFilterValueInactive] = useState('');
  const [page, setPage] = useState(FIRST_PAGE);
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE_OPTIONS[0]);
  const [count, setCount] = useState(0);
  const [tab, setTab] = useState(ResponsibleStatus.INACTIVE);
  const [phones, setPhones] = useState<Array<Phone>>([]);
  const [inactivePhones, setInactivePhones] = useState<Array<Phone>>([]);
  const [inactiveCount, setInactiveCount] = useState(-1);
  const [activeCount, setActiveCount] = useState(-1);
  const [drawerStatus, setDrawerStatus] = useState(false);
  const [selectedPhone, setSelectedPhone] = useState<Phone | null>(null);
  const [openExcludeDialog, setExcludeDialog] = useState(false);
  const throttledSearchTermActive = useThrottle(filterValueActive, ON_CHANGE_DELAY);
  const throttledSearchTermInactive = useThrottle(filterValueInactive, ON_CHANGE_DELAY);
  const { t } = useTranslation(['phones', 'common']);

  const { data: phonePagination, isSuccess: isActiveSuccess } = usePhonesQuery({
    query: throttledSearchTermActive,
    page,
    size: rowsPerPage,
  });

  const { data: inactivePhonePagination, isSuccess: isInactiveSuccess } = useInactivePhonesQuery({
    query: throttledSearchTermInactive,
    page,
    size: rowsPerPage,
  });

  const [deletePhone] = useDeletePhoneMutation();

  const handleOnDelete = async (id: number | undefined) => {
    if (id) {
      const response = await deletePhone(id);
      if ('data' in response) {
        toast.success(
          t('Telefone removido com sucesso!', {
            position: toast.POSITION.TOP_RIGHT,
          }),
        );
      }
    }
  };

  useEffect(() => {
    setPhones(() => (phonePagination ? phonePagination.content : []));
  }, [phonePagination, phonePagination?.content]);

  useEffect(() => {
    setInactivePhones(() => (inactivePhonePagination ? inactivePhonePagination.content : []));
  }, [inactivePhonePagination, inactivePhonePagination?.content]);

  useEffect(() => {
    if (isActiveSuccess && phonePagination && isInactiveSuccess && inactivePhonePagination) {
      const totalActive = phonePagination ? phonePagination?.totalElements : 0;
      const totalInactive = inactivePhonePagination ? inactivePhonePagination?.totalElements : 0;
      if (inactiveCount === -1) {
        // set tab on the first time
        setTab(totalInactive > 0 ? ResponsibleStatus.INACTIVE : ResponsibleStatus.ACTIVE);
      }
      setActiveCount(totalActive);
      setInactiveCount(totalInactive);
    }
  }, [phonePagination, inactivePhonePagination, isActiveSuccess, isInactiveSuccess, inactiveCount]);

  useEffect(() => {
    setCount(tab === ResponsibleStatus.ACTIVE ? activeCount : inactiveCount);
    setPage(FIRST_PAGE);
  }, [tab, activeCount, inactiveCount]);

  useEffect(() => {
    setPage(FIRST_PAGE);
  }, [throttledSearchTermActive, throttledSearchTermInactive]);

  useEffect(() => {
    if (!drawerStatus) {
      setSelectedPhone(null);
    }
  }, [drawerStatus]);

  const handleRowSelect = (phone: Phone) => {
    setSelectedPhone(phone);
    setDrawerStatus(true);
  };

  const handleToDelete = (phone: Phone): void => {
    setSelectedPhone(phone);
    setExcludeDialog(true);
  };

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    setDrawerStatus(open);
  };

  return (
    <Box
      component="div"
      sx={{
        width: '100%',
        height: '100%',
        pr: 3,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}
    >
      <PageTitle title={t('common:phone.list')} />

      <Box display="flex" justifyContent="space-between">
        <SearchComponent
          placeholder={t('search.phone.employee.or.result.center')}
          value={tab === ResponsibleStatus.ACTIVE ? filterValueActive : filterValueInactive}
          onFilterChange={(filter) => {
            if (tab === ResponsibleStatus.ACTIVE) {
              setFilterValueActive(filter);
            } else {
              setFilterValueInactive(filter);
            }
          }}
        />
        <Button
          onClick={toggleDrawer(true)}
          variant="text"
          sx={{ color: '#f97c06', textTransform: 'none', fontWeight: 800, fontSize: 14 }}
        >
          + {t('new.phone')}
        </Button>
      </Box>

      <PhoneTabs
        amount={inactiveCount}
        indexTab={tab}
        onChangeTab={(newValue: ResponsibleStatus) => setTab(newValue)}
      />

      <PhonesTable
        phones={phones}
        inactivePhones={inactivePhones}
        onRowSelect={handleRowSelect}
        onDelete={handleToDelete}
        tab={tab}
      />

      {Boolean(count) && (
        <TablePagination
          sx={{ height: 60 }}
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          labelRowsPerPage={t('lines.per.page')}
          labelDisplayedRows={({ from: pageFrom, to: pageTo, count: pageCount }) =>
            t(`${pageFrom}-${pageTo} de ${pageCount !== -1 ? pageCount : `mais de ${pageTo}`}`)
          }
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(event: unknown, newPage: number) => {
            setPage(newPage);
          }}
          onRowsPerPageChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setRowsPerPage(+event.target.value);
            setPage(FIRST_PAGE);
          }}
        />
      )}

      <StandardDialog
        title={t('Excluir Telefone(s)')}
        contentText={t(`Tem certeza que deseja excluir este(s) Telefone(s)?`)}
        open={openExcludeDialog}
        onClose={() => setExcludeDialog(false)}
        onConfirm={() => {
          setExcludeDialog(false);
          handleOnDelete(selectedPhone?.id);
        }}
        cancelText={t('Não')}
        confirmText={t('Sim')}
      >
        <PhoneNumberListDisplay value={selectedPhone ? selectedPhone.phones : []} />
      </StandardDialog>

      <Drawer
        anchor="right"
        open={drawerStatus}
        onClose={(event: React.SyntheticEvent, reason: string) => {
          if (reason === 'escapeKeyDown') {
            setDrawerStatus(false);
          }
        }}
      >
        <NewEditPhoneScreen phone={selectedPhone} setDrawerOpen={setDrawerStatus} />
      </Drawer>
    </Box>
  );
}
