import ResponsibleStatus from '@models/ResponsibleStatus';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

type PhoneTabProps = {
  amount: number;
  indexTab: ResponsibleStatus;
  onChangeTab: (newValue: ResponsibleStatus) => void;
};

export default function PhoneTabs(props: PhoneTabProps) {
  const { amount, indexTab, onChangeTab } = props;
  const { t } = useTranslation('phones');

  const CustomBadge = styled(Badge)(() => ({
    '& .MuiBadge-badge': {
      top: '50%',
      width: 25,
      transform: 'translateY(-50%)  translateX(120%)',
    },
  }));

  return (
    <Box sx={{ width: '100%', borderBottom: 1, borderBottomColor: '#DEDEDE' }}>
      <Tabs
        value={indexTab}
        onChange={(event: React.SyntheticEvent, newValue: ResponsibleStatus) => {
          onChangeTab(newValue);
        }}
        textColor="secondary"
        indicatorColor="secondary"
        aria-label="Phone tabs"
      >
        <Tab
          value={ResponsibleStatus.INACTIVE}
          label={
            <CustomBadge badgeContent={amount.toString()} color="warning">
              {t('terminations.or.closed')}
            </CustomBadge>
          }
          sx={{ textTransform: 'none', pr: 4, pb: 0 }}
        />
        <Tab value={ResponsibleStatus.ACTIVE} label={t('active')} sx={{ textTransform: 'none', pb: 0 }} />
      </Tabs>
    </Box>
  );
}
