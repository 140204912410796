import DateDisplay from '@/components/common/display/DateDisplay';
import SharingStatus from '@/components/common/display/sharingStatus';
import ActionsMenu, { ActionMenuOption } from '@components/common/actionsMenu';
import MonetaryDisplay from '@components/common/display/monetary';
import PeriodDisplay from '@components/common/display/period';
import SharingRequest, { SharingRequestStatus } from '@models/sharingRequest';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {
  IconButton,
  Radio,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

type RowFormats = string | number | boolean | SharingRequestStatus;

interface Column {
  id: 'period' | 'invoice' | 'total' | 'supplierName' | 'status' | 'processingDate' | 'actions';
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: RowFormats) => JSX.Element;
}

type RowData = {
  id: number;
  period: string;
  invoice: string;
  total: number;
  supplierName: string;
  status: SharingRequestStatus;
  processingDate: string;
  errorMessage: string;
  actions: boolean;
};

const useColumns = (): readonly Column[] => {
  const { t } = useTranslation(['sharing', 'common']);

  return [
    {
      id: 'period',
      label: t('period'),
      minWidth: 80,
      format: (value: RowFormats) => <PeriodDisplay value={String(value)} />,
    },
    {
      id: 'invoice',
      label: t('invoice'),
      minWidth: 120,
      format: (value: RowFormats) => <Typography noWrap>{String(value)}</Typography>,
    },
    {
      id: 'total',
      label: t('value'),
      minWidth: 100,
      format: (value: RowFormats) => <MonetaryDisplay value={Number(value)} />,
    },
    {
      id: 'supplierName',
      label: t('suplier'),
      minWidth: 60,
      format: (value: RowFormats) => <Typography noWrap>{String(value)}</Typography>,
    },
    {
      id: 'status',
      label: t('Status'),
      minWidth: 160,
      format: (value: RowFormats) => <SharingStatus status={value as SharingRequestStatus} />,
    },
    {
      id: 'processingDate',
      label: t('processing.date'),
      minWidth: 60,
      format: (value: RowFormats) => <DateDisplay dateStr={value ? String(value) : ''} />,
    },
  ];
};

const createRows = (content: Array<SharingRequest>) =>
  content.map(
    (item) =>
      ({
        id: item.id,
        period: item.period,
        invoice: item.invoice,
        total: item.total,
        supplierName: item.supplierName,
        status: item.status,
        processingDate: item.processingDate,
        errorMessage: item.errorMessage,
        actions: true,
      } as RowData),
  );

interface SharingTableRowProps {
  row: RowData;
  onClickRow: (clickedRow: RowData) => void;
  onRowEdit: (clickedRow: RowData) => void;
  onDeleteRow: (row: RowData) => void;
  onShowErrorMessage: (row: RowData) => void;
  selected: boolean;
}

function SharingTableRow(props: SharingTableRowProps) {
  const { row, onClickRow: handleOnClickRow, onRowEdit, onDeleteRow, onShowErrorMessage, selected } = props;

  const { t } = useTranslation(['sharing', 'common']);

  const columns = useColumns();
  return (
    <TableRow
      hover
      role="checkbox"
      tabIndex={-1}
      key={row.id}
      style={{ minHeight: '40px', height: '40px', maxHeight: '40px' }}
    >
      <TableCell padding="checkbox">
        <Radio
          size="small"
          sx={{
            color: 'gray',
            '&.Mui-checked': {
              color: '#F97C06',
            },
            '& .MuiSvgIcon-root': {
              fontSize: 16,
            },
          }}
          color="primary"
          checked={selected}
          onChange={() => handleOnClickRow(row)}
          inputProps={{
            'aria-label': 'select all desserts',
          }}
        />
      </TableCell>
      {columns.map((column) => {
        const value = row[column.id];
        const key = `${column.id}-${row.id}`;
        const formattedValue = column.format ? column.format(value) : value;
        const error = row.status === SharingRequestStatus.ERROR;
        return (
          <TableCell
            key={key}
            component="td"
            scope="row"
            align="left"
            onClick={() => handleOnClickRow(row)}
            sx={{ height: 5, maxWidth: column.minWidth, overflow: 'hidden', textOverflow: 'ellipsis' }}
          >
            <Stack direction="row">
              {formattedValue}
              {error && column.id === 'status' && (
                <IconButton onClick={() => onShowErrorMessage(row)}>
                  <OpenInNewIcon sx={{ width: 12, height: 12 }} />
                </IconButton>
              )}
            </Stack>
          </TableCell>
        );
      })}
      <TableCell align="center" component="td" scope="row" sx={{ fontSize: '13px', padding: 0 }}>
        <ActionsMenu
          actionList={
            [
              {
                icon: <EditOutlinedIcon />,
                label: t('edit.sharing.request'),
                actionCallback: () => onRowEdit(row),
              },
              {
                icon: <DeleteOutlineOutlinedIcon />,
                label: t('Excluir Lançamento'),
                actionCallback: () => onDeleteRow(row),
              },
            ] as Array<ActionMenuOption>
          }
        />
      </TableCell>
    </TableRow>
  );
}

type SharingTableProps = {
  items: Array<SharingRequest>;
  selectedItem: SharingRequest | null;
  onRowSelect: (item: SharingRequest) => void;
  onRowEdit: (item: SharingRequest) => void;
  onRowDelete: (item: SharingRequest) => void;
  onRowError: (item: SharingRequest) => void;
};

export default function SharingTable(props: SharingTableProps) {
  const { t } = useTranslation('sharing');
  const [rows, setRows] = useState<Array<RowData>>([]);
  const { items, selectedItem, onRowSelect, onRowEdit, onRowDelete, onRowError } = props;

  const columns = useColumns();

  useEffect(() => {
    setRows(createRows(items));
  }, [items]);

  return (
    <TableContainer>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="sharing request">
        <TableHead sx={{ fontWeight: 700 }}>
          <TableRow>
            <TableCell style={{ width: 40 }}> </TableCell>
            {columns.map((column) => (
              <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth, fontWeight: 'bold' }}>
                {t(column.label)}
              </TableCell>
            ))}
            <TableCell style={{ width: 40, fontWeight: 'bold' }}>{t('common:actions')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows &&
            rows.map((row: RowData) => (
              <SharingTableRow
                key={row.id}
                row={row}
                selected={selectedItem?.id === row.id}
                onClickRow={onRowSelect}
                onRowEdit={onRowEdit}
                onDeleteRow={onRowDelete}
                onShowErrorMessage={onRowError}
              />
            ))}
          {rows.length === 0 && (
            <TableRow>
              <TableCell colSpan={columns.length} align="center">
                <Typography sx={{ fontSize: 16, color: '#A8A8A8' }}>{t('Nenhum registro foi encontrado')}</Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
