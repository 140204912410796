import { SharingRequestStatus } from '@/app/models/sharingRequest';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface SharingStatusProps {
  status: SharingRequestStatus;
}

function SharingStatus(props: SharingStatusProps) {
  const { t } = useTranslation('sharingStatus');
  const { status } = props;
  const statusTranslations = (st: SharingRequestStatus) => {
    switch (st) {
      case SharingRequestStatus.NEW:
        return t('pending');
      case SharingRequestStatus.SENT:
        return t('processing');
      case SharingRequestStatus.ERROR:
        return t('error.processing');
      case SharingRequestStatus.OK:
        return t('processed');
      default:
        return '---';
    }
  };

  const colorTranslations = (st: SharingRequestStatus) => {
    switch (st) {
      case SharingRequestStatus.NEW:
        return '#FFC107';
      case SharingRequestStatus.SENT:
        return '#CCCCCC';
      case SharingRequestStatus.ERROR:
        return '#EF4747';
      case SharingRequestStatus.OK:
        return '#28A745';
      default:
        return '#CCCCCC';
    }
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {['NEW', 'SENT', 'ERROR', 'OK'].indexOf(status as string) !== -1 && (
        <div style={{ height: '8px', width: '8px', backgroundColor: colorTranslations(status), borderRadius: '4px' }} />
      )}

      <Typography sx={{ marginLeft: '6px' }} variant="body1">
        {statusTranslations(status)}
      </Typography>
    </div>
  );
}

export default SharingStatus;
