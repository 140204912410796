import StandardDialog from '@/components/common/StandardDialog';
import MonetaryDisplay from '@/components/common/display/monetary';
import PeriodDisplay from '@/components/common/display/period';
import NewEditSharingRequestScreen from '@/components/sharingRequest/newEditScreen';
import SharingTable from '@/components/sharingRequest/table';
import PageTitle from '@components/common/pageTitle';
import SharingRequest from '@models/sharingRequest';
import SyncIcon from '@mui/icons-material/Sync';
import { Box, Button, Divider, Drawer, Grid, TablePagination, Typography } from '@mui/material';
import {
  useDeleteSharingRequestMutation,
  useExecuteSharingRequestMutation,
  useGetPagedSharingRequestQuery,
} from '@services/sharingRequestApi';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const ROWS_PER_PAGE_OPTIONS = [10, 25, 50];
const FIRST_PAGE = 0;
const REFRESH_DELAY = 1000; // 1 second

const displaySharingRequest = (item: SharingRequest | null) => {
  const period = item ? item.period : '';
  const invoice = item ? item.invoice : '';
  const total = item ? item.total : 0;
  const supplierName = item ? item.supplierName : '';
  return (
    <Box width={480}>
      <Grid container spacing={1} sx={{ marginTop: 2 }}>
        <Grid item xs={3}>
          <Typography fontWeight={600}>Período:</Typography>
        </Grid>
        <Grid item xs={9}>
          <PeriodDisplay value={period} />
        </Grid>
        <Grid item xs={3}>
          <Typography fontWeight={600}>Nota Fiscal:</Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography>{invoice}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography fontWeight={600}>Valor (R$):</Typography>
        </Grid>
        <Grid item xs={9}>
          <MonetaryDisplay value={total} />
        </Grid>
        <Grid item xs={3}>
          <Typography fontWeight={600}>Fornecedor:</Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography>{supplierName}</Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default function ManageSharingRequestPage() {
  const { t } = useTranslation(['sharing', 'common']);

  const [page, setPage] = useState(FIRST_PAGE);
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE_OPTIONS[0]);
  const [count, setCount] = useState(0);
  const [items, setItems] = useState<Array<SharingRequest>>([]);
  const [drawerStatus, setDrawerStatus] = useState(false);
  const [selectedItem, setSelectedItem] = useState<SharingRequest | null>(null);
  const [openProcessDialog, setOpenProcessDialog] = useState(false);
  const [openExcludeDialog, setOpenExcludeDialog] = useState(false);
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [disableRefresh, setDisableRefresh] = useState(false);
  const [disableProcess, setDisableProcess] = useState(false);
  const [sharingFile, setSharingFile] = useState(null as unknown as File);

  const { data: getPagedSharingRequest, refetch } = useGetPagedSharingRequestQuery({
    page,
    size: rowsPerPage,
  });

  useEffect(() => {
    setItems(getPagedSharingRequest ? getPagedSharingRequest?.content : []);
    setCount(getPagedSharingRequest ? getPagedSharingRequest?.totalElements : 0);
  }, [getPagedSharingRequest]);

  useEffect(() => {
    if (!drawerStatus) {
      setSelectedItem(null);
    }
  }, [drawerStatus]);

  useEffect(() => {
    setDisableProcess(!selectedItem);
  }, [selectedItem]);

  const handleRowSelect = (item: SharingRequest) => {
    setSelectedItem(item);
  };

  const handleRowEdit = (item: SharingRequest) => {
    setSelectedItem(item);
    setDrawerStatus(true);
  };

  const [deleteItem] = useDeleteSharingRequestMutation();
  const [processItem] = useExecuteSharingRequestMutation();

  const confirmDelete = async (sharingRequest: SharingRequest | null) => {
    if (sharingRequest && sharingRequest.id) {
      const response = await deleteItem(sharingRequest.id);
      if ('data' in response) {
        toast.success(
          t('sharing.request.successfully.removed', {
            position: toast.POSITION.TOP_RIGHT,
          }),
        );
      }
    }
  };

  const confirmProcess = async (sharingRequest: SharingRequest | null) => {
    if (sharingRequest && sharingRequest.id && sharingFile) {
      const formData = new FormData();
      formData.append('file', sharingFile || '');
      formData.append('id', String(sharingRequest.id));
      const response = await processItem(formData);
      if ('data' in response) {
        toast.success(
          t('Lançamento enviado para processamento com sucesso.', {
            position: toast.POSITION.TOP_RIGHT,
          }),
        );
      }
      setOpenProcessDialog(false);
    }
  };

  const handleToDelete = (sharingRequest: SharingRequest): void => {
    setSelectedItem(sharingRequest);
    setOpenExcludeDialog(true);
  };

  const handleStatusError = (sharingRequest: SharingRequest): void => {
    setSelectedItem(sharingRequest);
    setOpenErrorDialog(true);
  };

  const handleProcess = (): void => {
    setOpenProcessDialog(true);
  };

  const refreshItems = (): void => {
    setDisableRefresh(true);
    refetch();
    setTimeout(() => setDisableRefresh(false), REFRESH_DELAY);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '100%', height: '100%', pr: 3 }}>
      <PageTitle title={t('common:sharing.requests')} />

      <Box display="flex" flexDirection="row" gap={2}>
        <Button
          onClick={refreshItems}
          variant="text"
          sx={{ color: '#f97c06', textTransform: 'none', fontWeight: 800, fontSize: 14 }}
          disabled={disableRefresh}
        >
          <SyncIcon /> {t('update.status')}
        </Button>
        <Box flexGrow={1} />

        <Button
          onClick={() => {
            setSelectedItem(null);
            setDrawerStatus(true);
          }}
          variant="text"
          sx={{ color: '#f97c06', textTransform: 'none', fontWeight: 800, fontSize: 14 }}
        >
          + {t('new.sharing.request')}
        </Button>

        <Button variant="primary" onClick={handleProcess} disabled={disableProcess}>
          {t('process')}
        </Button>
      </Box>

      <SharingTable
        items={items}
        selectedItem={selectedItem}
        onRowSelect={handleRowSelect}
        onRowEdit={handleRowEdit}
        onRowDelete={handleToDelete}
        onRowError={handleStatusError}
      />

      {Boolean(count) && (
        <TablePagination
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          labelRowsPerPage={t('lines.per.page')}
          labelDisplayedRows={({ from: pageFrom, to: pageTo, count: pageCount }) =>
            t(`${pageFrom}-${pageTo} de ${pageCount !== -1 ? pageCount : `mais de ${pageTo}`}`)
          }
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(event: unknown, newPage: number) => {
            setPage(newPage);
          }}
          onRowsPerPageChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setRowsPerPage(+event.target.value);
            setPage(FIRST_PAGE);
          }}
        />
      )}

      <StandardDialog
        title={t('Excluir Lançamento')}
        contentText={t(`Tem certeza que deseja excluir este Lançamento?`)}
        open={openExcludeDialog}
        onClose={() => setOpenExcludeDialog(false)}
        onConfirm={() => {
          setOpenExcludeDialog(false);
          confirmDelete(selectedItem);
        }}
        cancelText={t('Não')}
        confirmText={String(t('Sim'))}
      >
        {displaySharingRequest(selectedItem)}
      </StandardDialog>

      <StandardDialog
        title={t('process.sharing.request')}
        contentText={t(
          `Tem certeza que deseja enviar este lançamento para o processamento? Esta ação poderá levar alguns minutos. Atualize o status para verificar.`,
        )}
        open={openProcessDialog}
        onClose={() => setOpenProcessDialog(false)}
        onConfirm={() => {
          confirmProcess(selectedItem);
        }}
        cancelText={t('common:cancel')}
        confirmText={String(t('process'))}
        disableConfirm={!sharingFile}
      >
        <>
          <Divider sx={{ my: 3 }} />
          {displaySharingRequest(selectedItem)}
          <Divider sx={{ my: 3 }} />
          <Typography fontSize="14px" sx={{ marginBottom: '24px' }}>
            {t('Anexe um arquivo .xls do Rateio Indireto para processar')}:
          </Typography>
          <Typography fontWeight={600}>{t('Rateio Indireto')}:</Typography>
          <div style={{ display: 'flex' }}>
            <input
              style={{ width: '100%', marginTop: '0px', marginBottom: '0px' }}
              disabled
              value={sharingFile ? sharingFile.name : '---'}
            />
            <Button variant="contained" component="label" sx={{ minWidth: '30%' }}>
              <Typography sx={{ fontSize: '12px' }}>{t('Escolher Arquivo')}</Typography>
              <input
                type="file"
                onChange={(e) => {
                  if (e.target.files) {
                    setSharingFile(e.target.files[0]);
                  }
                }}
                hidden
                multiple={false}
              />
            </Button>
          </div>
        </>
      </StandardDialog>

      <StandardDialog
        title={t('Error no Processamento')}
        contentText={t(`Aconteceu o seguinte erro no processamento:`)}
        open={openErrorDialog}
        onClose={() => setOpenErrorDialog(false)}
        cancelText={t('Fechar')}
      >
        <Box minWidth={480} bgcolor="#F4F4F4" borderRadius={2} border="1px solid #CCCCCC" sx={{ marginY: 2, p: 2 }}>
          <Typography color="#4B4B4B" />
          {selectedItem?.errorMessage}
        </Box>
      </StandardDialog>

      <Drawer
        anchor="right"
        open={drawerStatus}
        onClose={(event: React.SyntheticEvent, reason: string) => {
          if (reason === 'escapeKeyDown') {
            setDrawerStatus(false);
          }
        }}
      >
        <NewEditSharingRequestScreen sharingRequest={selectedItem} setDrawerOpen={setDrawerStatus} />
      </Drawer>
    </Box>
  );
}
