import StandardDialog from '@/components/common/StandardDialog';
import InputPhone from '@components/phones/inputPhone';
import PhoneResponsible from '@components/phones/responsible';
import { CostCenter } from '@models/costCenter';
import Person from '@models/person';
import Phone, { NewEditPhone } from '@models/phone';
import ResponsibleType from '@models/responsibleType';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Divider, IconButton, Stack, TextField, Typography } from '@mui/material';
import { useCreatePhoneMutation, useUpdatePhoneMutation } from '@services/phoneApi';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import uuid from 'react-uuid';

interface NewEditPhoneProps {
  phone: Phone | null;
  setDrawerOpen: (open: boolean) => void;
}

interface PhoneNumber {
  value: string;
  id: string;
}

const createPhoneNumber = (value: string) => ({ id: uuid(), value } as PhoneNumber);

export default function NewEditPhoneScreen(props: NewEditPhoneProps) {
  const PHONE_NUMBER_SIZE = 15;
  const { t } = useTranslation(['phones', 'common']);
  const { phone, setDrawerOpen } = props;

  const [phoneNumbers, setPhoneNumbers] = useState([createPhoneNumber('')]);
  const [saveDisabled, setSaveDisabled] = useState(true);

  const [responsibleOption, setResponsibleOption] = useState(ResponsibleType.PERSON);
  const [costCenter, setCostCenter] = useState<CostCenter | null>(null);
  const [person, setPerson] = useState<Person | null>(null);
  const [projectResponsible, setProjectResponsible] = useState('');
  const [comments, setComments] = useState('');

  const [createPhone] = useCreatePhoneMutation();
  const [updatePhone, { isLoading }] = useUpdatePhoneMutation();

  const [openSaveDialog, setOpenSaveDialog] = useState(false);

  useEffect(() => {
    if (phone) {
      setPhoneNumbers(phone.phones.map((value: string) => createPhoneNumber(value)));
      const type = ResponsibleType[phone.type];
      setResponsibleOption(type);
      if (type === ResponsibleType.PERSON) {
        setPerson({
          name: phone.personName,
          email: phone.personEmail,
          document: phone.personBadge,
          costCenterCode: phone.projectCode,
        } as Person);
      } else {
        setCostCenter({
          code: phone.projectCode,
          name: phone.projectName,
        } as CostCenter);
        setProjectResponsible(phone.projectResponsible);
      }
      if (phone.area) {
        setComments(phone.area);
      }
    }
  }, [phone]);

  useEffect(() => {
    const hasInvalidPhones =
      phoneNumbers.filter((phoneNumber) => phoneNumber.value.length === PHONE_NUMBER_SIZE).length === 0;
    const hasInvalidCostCenter = responsibleOption === ResponsibleType.PROJECT && costCenter === null;
    const hasInvalidEmployer = responsibleOption === ResponsibleType.PERSON && person === null;
    const disabled = hasInvalidPhones || hasInvalidCostCenter || hasInvalidEmployer;
    setSaveDisabled(disabled);
  }, [phoneNumbers, responsibleOption, costCenter, person]);

  const handleInputChange = (id: string, event: React.ChangeEvent<HTMLInputElement>) => {
    const newPhones = [...phoneNumbers];
    for (let index = 0; index < newPhones.length; index += 1) {
      if (newPhones[index].id === id) {
        newPhones[index].value = event.target.value;
      }
    }
    setPhoneNumbers([...newPhones]);
  };

  const handleAddPhoneNumber = () => {
    setPhoneNumbers([...phoneNumbers, createPhoneNumber('')]);
  };

  const handleRemovePhoneNumber = (idToRemove: string) => {
    const changedPhoneNumbers = phoneNumbers.filter((phoneNumber) => phoneNumber.id !== idToRemove);
    setPhoneNumbers(changedPhoneNumbers);
  };

  const checkDuplicates = (itens: Array<string>) => new Set(itens).size !== itens.length;

  const handleSavePhone = async () => {
    const newFormattedPhoneNumber = phoneNumbers.map((phoneNumber) => phoneNumber.value.replace(/\s/g, ''));
    if (checkDuplicates(newFormattedPhoneNumber)) {
      toast.error(
        t('Existem números de telefone duplicados!', {
          position: toast.POSITION.TOP_RIGHT,
        }),
      );
      return;
    }
    const newEditPhone = {
      id: phone ? phone.id : null,
      phones: newFormattedPhoneNumber,
      personEmail: responsibleOption === ResponsibleType.PERSON ? person?.email : null,
      projectCode: responsibleOption === ResponsibleType.PROJECT ? costCenter?.code : null,
      projectResponsible: responsibleOption === ResponsibleType.PROJECT ? projectResponsible : null,
      area: comments,
    } as NewEditPhone;

    const response = phone ? await updatePhone(newEditPhone) : await createPhone(newEditPhone);
    if ('data' in response) {
      toast.success(
        t('phone.number.saved.successfully', {
          position: toast.POSITION.TOP_RIGHT,
        }),
      );
      setDrawerOpen(false);
    }
  };

  return (
    <div
      style={{
        minWidth: 480,
        backgroundColor: '#FAFAFA',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
      }}
    >
      <Typography
        sx={{
          marginY: '1rem',
          fontWeight: 'bold',
          fontSize: 18,
          pl: 2,
        }}
      >
        {phone ? t('edit.phone') : t('new.phone')}
      </Typography>

      <div>
        {phoneNumbers.map((phoneNumber: PhoneNumber, index) => (
          <Box
            key={phoneNumber.id}
            component="div"
            sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}
          >
            <InputPhone value={phoneNumber.value} onChange={(event) => handleInputChange(phoneNumber.id, event)} />
            {index !== 0 && (
              <IconButton
                aria-label="remove"
                onClick={() => handleRemovePhoneNumber(phoneNumber.id)}
                sx={{
                  width: 32,
                  height: 40,
                  borderRadius: 0,
                  border: '1px solid',
                  borderColor: 'primary.main',
                  bgcolor: '#f5f5f5',
                }}
              >
                <CloseIcon sx={{ width: 16 }} />
              </IconButton>
            )}
          </Box>
        ))}
        <Button
          onClick={handleAddPhoneNumber}
          variant="text"
          sx={{ color: '#0493C7', textTransform: 'none', pl: 3, fontSize: 14 }}
        >
          + {t('add.another')}
        </Button>
      </div>

      <Divider sx={{ marginX: 3, marginY: 2 }} />

      <PhoneResponsible
        option={responsibleOption}
        person={person}
        costCenter={costCenter}
        projectResponsible={projectResponsible}
        onChangeOption={(newOption: ResponsibleType) => {
          setResponsibleOption(newOption);
        }}
        onChangePerson={(newPerson: Person) => {
          setPerson(newPerson);
        }}
        onChangeCostCenter={(newCostCenter: CostCenter) => {
          setCostCenter(newCostCenter);
        }}
        onChangeProjectResponsible={(newValue: string) => setProjectResponsible(newValue)}
      />

      <Divider sx={{ m: 3 }} />

      <Box sx={{ m: 3 }}>
        <Typography sx={{ fontWeight: 'bold', fontSize: 14 }}>{t('note.optional')}</Typography>
        <TextField
          id="outlined-multiline-static"
          value={comments}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setComments(event.target.value);
          }}
          multiline
          fullWidth
          rows={3}
          sx={{
            bgcolor: 'white',
            borderColor: 'gray',
            '& .MuiOutlinedInput-root': {
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: 'gray',
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: 'gray',
              },
            },
          }}
          inputProps={{ maxLength: 255 }}
        />
      </Box>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          marginBottom: '8px',
          marginTop: 'auto',
        }}
      >
        <Stack direction="row" justifyContent="flex-end" alignItems="baseline" margin={3} spacing={2}>
          <Button
            variant="secondary"
            onClick={() => {
              setDrawerOpen(false);
            }}
          >
            {t('common:cancel')}
          </Button>

          <LoadingButton
            variant="primary"
            loading={isLoading}
            onClick={() => {
              setOpenSaveDialog(true);
            }}
            disabled={saveDisabled}
          >
            {phone ? t('common:save') : t('add')}
          </LoadingButton>
        </Stack>
      </div>
      <StandardDialog
        title={phone ? t('save.phone.number') : t('add.phone.number')}
        contentText={t('sure.save.phone')}
        open={openSaveDialog}
        onClose={() => setOpenSaveDialog(false)}
        onConfirm={() => {
          setOpenSaveDialog(false);
          handleSavePhone();
        }}
        cancelText={t('Não')}
        confirmText={t('Sim')}
      />
    </div>
  );
}
